<template>
    <div class="multi-select-group">
        <label
            v-for="(option, idx) in options"
            :key="idx"
            :class="{ 'select-container': true, 'block': block, 'inline': inline }"
        >
            <template v-if="typeof option == 'string'">
                {{ option }}
                <input :type="(type == 'switch') ? 'checkbox' : type" :name="name" :value="option" v-model="localValue">
            </template>
            <template v-else>
                {{ option.text }}
                <input :type="(type == 'switch') ? 'checkbox' : type" :name="name" :value="option.value" v-model="localValue">
            </template>
            <span :class="type"></span>
        </label>
    </div>
</template>
<script>
export default {
    props: {
        name: {
            default: () => 'r' + Math.random().toString(),
            type: String
        },
        options: {
            default: () => [],
            type: Array
        },
        type: {
            default: "radio",
            type: String
        },
        block: {
            default: true,
            type: Boolean
        },
        inline: {
            default: false,
            type: Boolean
        },
        value: {
            default: null,
            type: [Array, String, Number]
        }
    },
    data() {
        return {
            localValue: (this.value || [])
        };
    },
    watch: {
        value() {
            this.localValue = (this.value || []);
        },
        localValue() {
            this.$emit("input", this.localValue);
        }
    }
}
</script>
<style scoped>
.select-container {
    position: relative;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0px 55px;
}

.select-container.block {
    display: block;
}

.select-container.inline {
    display: inline-block;
}

.select-container input {
    display: none;
    cursor: pointer;
}

.radio {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 50%;
}

.switch {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 50px;
    background-color: #eee;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 25px;
}

.select-container input:disabled ~ .radio,
.select-container input:disabled ~ .checkbox,
.select-container input:disabled ~ .switch,
.select-container:hover input ~ .radio,
.select-container:hover input ~ .checkbox,
.select-container:hover input ~ .switch {
    background-color: #ccc;
}

.select-container input:checked:not(:disabled) ~ .checkbox,
.select-container input:checked:not(:disabled) ~ .switch {
    background-color: #8E9BD5;
}

.radio:before {
    content: "";
    position: absolute;
    transform: scale(0);
    transition: 115ms all ease-in;
}

.select-container input:checked ~ .radio:before {
    display: block;
    transform: scale(1);
}

.select-container .radio:before {
    top: 3px;
    left: 3px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
	background: #8E9BD5;
}

.switch:before {
    content: "";
    position: absolute;
    transition: 115ms all ease-in;
    top: 2px;
    left: 2px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: #ffffff;
    border: 2px solid rgba(0, 0, 0, 0.2);
}

.select-container input:checked ~ .switch:before {
    background: #ffffff;
    transform: translateX(25px);
}

.checkbox {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 3px;
}

.checkbox:before {
    content: "";
    position: absolute;
    transform: scale(0);
    transition: 115ms all ease-in;
}

.select-container input:checked ~ .checkbox:before {
    left: 7px;
    top: 2px;
    width: 7px;
    height: 15px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg) scale(1);
}

</style>