<template>
    <div class="custom-form">
        <fieldset :disabled="disabled">
            <h4 v-if="title">{{ title }}</h4>
            <em v-if="description" class="text-muted">{{ description }}</em>
            <ol :class="{ 'no-numbers': noNumbers }">
                <template v-for="(field, idx) in fields">
                    <slot v-if="field.slotName" :name="field.slotName" :field="field" :index="idx"></slot>
                    <form-field
                        v-else-if="!field.removed"
                        :key="field.id"
                        :field="field"
                        v-model="model[field.id || field.name || field.label]"
                        @input="changeInput(field)"
                        @onButtonClick="onButtonClick(field, model)"
                        @onFieldClick="$emit('onFieldClick', field)"
                    >
                        <template v-slot:controls>
                            <div v-if="Array.isArray(field.controls)" class="form-input-edit-controls">
                                <button
                                    v-for="(control, idx2) in field.controls"
                                    :key="idx + '.' + idx2"
                                    :class="control.variant"
                                    :disabled="checkControlEnabled(control, field, idx)"
                                    @click="$emit('onControlClick', control.command, field, idx)"
                                >
                                    <font-awesome-icon :icon="control.icon"></font-awesome-icon>
                                </button>
                            </div>
                        </template>
                    </form-field>
                </template>
            </ol>
            <div
                v-if="submit"
                class="text-center my-4"
            >
                <b-button
                    size="lg"
                    variant="primary"
                    @click="$emit('onSubmit', model)"
                    pill
                >
                    <font-awesome-icon icon="file" class="mr-2"></font-awesome-icon>
                    Submit
                </b-button>
            </div>
            <!--<pre>{{ model }}</pre>-->
        </fieldset>
    </div>
</template>
<script>
import FormField from "./FormField.vue"

export default {
    name: "f2-form",
    components: { FormField },
    props: [
        "title",
        "description",
        "fields",
        "prefill",
        "useControls",
        "disabled",
        "submit",
        "noNumbers"
    ],
    data() {
        return {
            model: Object.assign({}, (this.prefill || {}))
        };
    },
    methods: {
        changeInput(field) {
            this.$emit("inputChange", field, this.model[field.name || field.label]);
        },
        checkControlEnabled(control, field, idx) {
            if (typeof control.checkEnabled == 'function')
                return !control.checkEnabled(this, field, idx, control);
            return false;
        },
        onButtonClick(field, model) {
            this.$emit("onButtonClick", field, model);
        }
    }
}
</script>
<style scoped src="../../assets/css/forms.css"></style>
<style scoped>
    .custom-form {
        display: flexbox;
        min-width: 100%;
        align-content: center;
        justify-content: center;
        /* border: 1px dotted black; */        
    }
    .custom-form ol {
        padding: 0px;
    }
    .custom-form ol li {
        /*min-width: 400px;*/
        margin: 0px 15px;
    }
    .no-numbers {
        list-style-type: none;
    }
    .form-input-edit-controls {
        margin: -5px -15px;
        float: right;
        background: none;
    }
    .form-input-edit-controls > button {
        background-color: transparent;
        border: 0.1px solid #d5d5d5;
        max-width: 75px;
        max-height: 75px;
        width: 50px;
    }
    .form-input-edit-controls > button.primary:hover:not(:disabled) {
        background: #007bff;
        color: #ffffff;
        transition-duration: 0.25s;
    }
    .form-input-edit-controls > button.danger:hover {
        background-color: #dc3545;
        color: #ffffff;
        transition-duration: 0.25s;
    }
    .form-input-group {
        width: 100%;
        font: "Times New Roman";
        border-radius: 6px;
        border-spacing: 1px;
        background-color: none;
        padding: 5px 15px;
    }
    .form-input2 {
        border: none;
        outline: none;
        background-color: transparent;
        width: 100%;
        font-size: 20px;
    }
</style>