<template>
    <custom-select
        v-if="field.subtype == 'select'"
        :options="field.options"
        :value="value"
        :disabled="disabled"
        @input="onInput"
    ></custom-select>
    <radio-select
        v-else
        :type="field.subtype"
        :options="field.options"
        :value="value"
        :inline="field.inline"
        :disabled="disabled"
        @input="onInput"
    ></radio-select>
</template>
<script>
import CustomSelect from './CustomSelect.vue'
import RadioSelect  from './RadioSelect.vue'
export default {
    components: { CustomSelect, RadioSelect },
    props: ["field", "value", "disabled"],
    methods: {
        onInput(value) {
            this.$emit("input", value);
        }
    }
}
</script>