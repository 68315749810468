<template>
    <div
        v-if="field.type == 'text'"
        class="text-element"
        @click="$emit('onFieldClick', field)"
    >
        <slot name="controls"></slot>
        <h1 v-if="field.subtype == 'header1'">{{ field.label }}</h1>
        <h2 v-else-if="field.subtype == 'header2'">{{ field.label }}</h2>
        <h3 v-else-if="field.subtype == 'header3'">{{ field.label }}</h3>
        <p  v-else>{{ field.label }}</p>
    </div>
    <li
        v-else
        :key="field.id"
        :class="{ 'form-item': true, 'mt-3': true, 'focused': focused }"
    >
        <slot name="controls"></slot>
        <div @click="$emit('onFieldClick', field)">
            <label
                v-if="field.type != 'text'"
                class="form-input-group-lbl"
                :for="field.label"
            >
                {{ field.label }} <em v-if="field.required" class="text-danger">*</em>
            </label>
            <div v-if="field.options && field.options.helpText" class="form-input-group-helptxt">
                {{ field.options.helpText }}
            </div>
            <textarea
                v-if="field.type == 'input' && field.subtype == 'multiline'"
                :id="field.label"
                ref="input"
                @focus="changeFocus()"
                @blur="changeFocus()"
                class="form-input2"
                :placeholder="(field.placeholder || field.label)"
                v-model="fieldValue"
                @input="changeInput(field)"></textarea>
            <input
                v-else-if="field.type == 'input'"
                :id="field.label"
                ref="input"
                @focus="changeFocus()"
                @blur="changeFocus()"
                class="form-input2"
                :placeholder="(field.placeholder || field.label)"
                :type="(field.subtype || 'text')"
                v-model="fieldValue"
                @input="changeInput(field)" />
            <custom-select
                v-else-if="field.type == 'select'"
                :id="field.label"
                ref="input"
                @focus="changeFocus()"
                @blur="changeFocus()"
                class="m-4"
                size="lg"
                :label="field.label"
                :options="field.options"
                v-model="fieldValue"
                @input="changeInput(field)"
            >
                <!--<b-select
            ></b-select>-->
            </custom-select>
            <choice-input
                v-else-if="field.type == 'choice'"
                :id="field.label"
                ref="input"
                @focus="changeFocus()"
                @blur="changeFocus()"
                class="m-2"
                size="lg"
                :label="field.label"
                :options="field.options"
                :field="field"
                v-model="fieldValue"
                @input="changeInput(field)"
            ></choice-input>
            <div v-else-if="field.type == 'check'">
                <b-checkbox
                    :id="field.label"
                    ref="input"
                    @focus="changeFocus()"
                    @blur="changeFocus()"
                    v-model="fieldValue"
                    @input="changeInput(field)"
                    size="lg"
                    :switch="field.subtype == 'switch'"
                    class="mb-2"
                ></b-checkbox>
            </div>
            <b-checkbox-group
                v-else-if="field.type == 'checkbox-group'"
                :id="field.label"
                ref="input"
                :options="field.options"
                v-model="fieldValue"
                @input="changeInput(field)"
            ></b-checkbox-group>
            <textarea
                v-else-if="field.type == 'textarea'"
                :id="field.label"
                ref="input"
                @focus="changeFocus()"
                @blur="changeFocus()"
                class="form-input2"
                :placeholder="(field.placeholder || field.label)"
                v-model="fieldValue"
                @input="changeInput(field)"></textarea>
            <div v-else-if="field.type == 'button'" class="text-center mb-4">
                <b-button
                    variant="primary"
                    size="lg"
                    pill
                    @click="$emit('onButtonClick')"
                    :disabled="field.options.working"
                >
                    <b-spinner v-if="field.options && field.options.working"></b-spinner>
                    <font-awesome-icon v-else icon="file"></font-awesome-icon> {{ field.label }}
                </b-button>
                <!--<p class="mt-2">By submitting this form, you are agreeing to this site's terms of use.</p>-->
            </div>
            <div v-else-if="field.type == 'file'" class="text-center mb-2">
                <b-button
                    variant="primary"
                    size="lg"
                    pill
                >
                    <font-awesome-icon icon="upload" class="mr-2"></font-awesome-icon>
                    {{ field.text }}
                </b-button>
                <p class="mt-2">Allowed filetypes: {{ field.options.allowedExtensions.join(", ") }}</p>
                <p>Maximum upload size: 5MB</p>
            </div>
        </div>
    </li>
</template>
<script>
import CustomSelect     from './CustomSelect.vue';
import ChoiceInput from './ChoiceInput.vue';

export default {
  components: { CustomSelect, ChoiceInput },
    props: [
        "field",
        "value"
    ],
    data() {
        return {
            fieldValue  : this.value,
            focused     : false
        };
    },
    methods: {
        changeInput() {
            this.$emit("input", this.fieldValue);
            //this.$emit("inputChange", field, this.model[field.name || field.label]);
        },
        changeFocus() {
            //this.$refs.input[0].classList.toggle("focused");
            this.focused = !this.focused;
        }
    }
}
</script>
<style scoped>
    .form-input-edit-controls {
        margin: -5px -15px;
        float: right;
        background: none;
    }
    .form-input-edit-controls > button {
        background-color: transparent;
        border: 0.1px solid #d5d5d5;
        border-right: none;
        max-width: 75px;
        max-height: 75px;
        width: 50px;
    }
    .form-input-edit-controls > button:last-of-type {
        border-top-right-radius: 6px;
    }
    .form-input-edit-controls > button.primary:hover:not(:disabled) {
        background: #007bff;
        color: #ffffff;
        transition-duration: 0.25s;
    }
    .form-input-edit-controls > button.danger:hover {
        background-color: #dc3545;
        color: #ffffff;
        transition-duration: 0.25s;
    }
    .form-input-group {
        width: 100%;
        font: "Times New Roman";
        border-radius: 6px;
        border-spacing: 1px;
        background-color: #ffffff;
        padding: 5px 15px;
    }
    /*.form-input-group.focused {
        outline: none;
        border-bottom: 3px solid black;
        background-color: #f5f5f5;
        transition-duration: 0.25s;
        box-shadow: 2.5px 2.5px 2px 1px rgba(0,0,0,0.3);
    }
    .form-input-group-lbl {
        font-weight: bold;
        margin-bottom: 1px;
    }
    .form-input:not(input[type="checkbox"]) {
        border: none;
        border: 0.1px solid #d5d5d5;
        border-bottom: 3px dotted #b5b5b5;
        font-size: 18px;
        width: 100%;
        padding: 5px 15px;
        border-radius: 6px;
    }*/
    /*.form-input:focus {
        outline: none;
        border-bottom: 3px solid black;
        background-color: #f5f5f5;
        transition-duration: 0.25s;
        box-shadow: 12px 12px 2px 1px #b5b5b5;
    }
    .form-input.primary:focus {
        border-bottom: 3px solid #afaffa;
    }*/
    .form-input-group-lbl {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 5px;
        user-select: none;
    }
    .form-input-group-helptxt {
        font-size: 13px;
        font-style: italic;
        color: #818181;
        margin-bottom: 5px;
        user-select: none;
    }
    .text-element {
        margin: 4% 0%;
        user-select: none;
    }
    .form-input2 {
        padding: 5px 10px;
        border: 2px solid #8f8f8f;
        border-radius: 3px;
        outline: none;
        background-color: #fff;
        width: 100%;
        font-size: 20px;
    }
    .form-input2:hover:not(:disabled):not(:focus):not(:active) {
        /* box-shadow: 1.5px 1.5px 0 0 rgba(0, 0, 0, 0.3); */
        border-color: #000;
    }
    .form-input2:focus, .form-input2:active {
        border-color: #8E9BD5;
    }
    .form-input2:disabled {
        border-color: #8f8f8f;
        background-color: #d6d6d6;
        box-shadow: 0 0 0 0;
    }
    /* For desktops and tablets */
    @media screen and (min-width: 600px) {
        /*.dropdown {
            width: 50%;
        }
        .form-input-group-lbl {
            max-width: 50%;
        }
        .form-input2 {
            display: block;
            width: 50%;
        }*/
    }
</style>