<template>
    <div :class="{ dropdown: true, active: active }">
        <div class="select" @click="active = !active">
            <slot name="label" :selectedOption="selectedOption" :label="label" :icon="icon">
                <font-awesome-icon
                    v-if="icon || (selectedOption && selectedOption.icon)"
                    :icon="(selectedOption) ? selectedOption.icon : icon"
                    class="mr-2"
                ></font-awesome-icon>
                <span>{{ (selectedOption) ? selectedOption.text : label }}</span>
            </slot>
            <font-awesome-icon :icon="dropIcon"></font-awesome-icon>
        </div>
        <ul :class="{ 'dropdown-menu': true, show: active && !disabled }">
            <li
                v-for="(option, idx) in options"
                :key="idx"
                @click="setValue(option.value)"
            >
                <font-awesome-icon
                    v-if="option.icon"
                    :icon="option.icon"
                    class="mr-2"
                ></font-awesome-icon>
                {{ option.text }}
            </li>
        </ul>
    </div>
</template>
<script>

export default {
    props: {
        options: {
            default: () => [],
            type: Array
        },
        label: {
            default: "Select an option",
            type: String
        },
        icon: {
            default: null,
            type: String
        },
        dropIcon: {
            default: "chevron-down",
            type: String
        },
        value: {
            default: null,
            type: [String, Number]
        },
        disabled: {
            default: false,
            type: Boolean
        }
    },
    data() {
        return {
            active: false
        };
    },
    methods: {
        setValue(value) {
            this.$emit("input", value);
            this.active = false;
        }
    },
    computed: {
        selectedOption() {
            return this.options.find((option) => option.value == this.value);
        }
    }
}
</script>
<style scoped>
.dropdown {
    min-width: 200px;
    background-color: #fff;
    border-radius: 3px;
    border: 2px solid rgba(0, 0, 0, 0.2);
    transition: all .5s ease;
    position: relative;
    font-size: 14px;
    color: #474747;
    height: 100%;
    text-align: left;
}
.dropdown-themed {
    background-color: #8E9BD5;
    color: #f2f2f2;
}
.dropdown .select {
    cursor: pointer;
    display: block;
    padding: 10px;
}
.dropdown .select > svg:first-of-type {
    float: left;
    margin: 5px 2px;
}
.dropdown .select > svg:last-of-type {
    color: #888;
    cursor: pointer;
    transition: all .3s ease-in-out;
    float: right;
    margin: 5px 2px;
}
.dropdown.active .select > svg:last-of-type {
    transform: rotate(-180deg)
}
.dropdown:hover {
    border-color: #8E9BD5;
    box-shadow: 0 0 4px #474747;
}
.dropdown:active:not(.dropdown-themed) {
    background-color: #f8f8f8;
}
.dropdown.active:hover,
.dropdown.active {
    box-shadow: 0 0 4px #474747;
    border-radius: 2px 2px 0 0;
}
.dropdown .dropdown-menu {
    position: absolute;
    background-color: #fff;
    width: 100%;
    left: 0;
    margin-top: 1px;
    box-shadow: 0 1px 2px #474747;
    border-radius: 0 1px 2px 2px;
    overflow: hidden;
    display: none;
    max-height: 144px;
    overflow-y: auto;
    z-index: 99;
    transition: all 0.5s ease-in-out;
}
.dropdown .dropdown-menu li > svg:first-of-type {
    float: left;
    margin: 5px 2px;
}
.dropdown .dropdown-menu li {
    padding: 10px;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
    user-select: none;
} 
.dropdown .dropdown-menu {
    padding: 0;
    list-style: none;
}
.dropdown .dropdown-menu li:hover {
    background-color: #adadad;
}
.dropdown .dropdown-menu li:active {
    background-color: #e2e2e2;
}
.dropdown .dropdown-menu.show {
    display: block;
}
fieldset:disabled .dropdown {
    background-color: #d6d6d6;
    border-color: #474747;
    cursor: not-allowed;
}
fieldset:disabled .dropdown:hover,
fieldset:disabled .dropdown.active:hover {
    box-shadow: none;
}
fieldset:disabled .dropdown-menu.show {
    display: none;
}
fieldset:disabled .dropdown .select > svg:last-of-type {
    display: none;
}
</style>